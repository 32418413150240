import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { HeaderButton } from "../../../components";


const ProfilePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { orderList } = useSelector((state: any) => state.order);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const today = new Date();

    // const effectServiceCall = useRef(false);
    // useEffect(() => {
    //     if (!effectServiceCall.current) {
    //         dispatch(fetchOrderListData());
    //     }
    //     return () => { effectServiceCall.current = true; };
    // }, [dispatch]);

    // const dropdownRef = useRef<HTMLDivElement>(null);
    // useOutsideClick({
    //     ref: dropdownRef,
    //     handler: () => setIsOpen(false),
    // });

    // const toggleAddModal = () => {
    //     setOpenModal(!openModal);
    // };

    return (
        <>
            <div className="flex flex-col justify-start gap-3 items-start w-full">
                <div className="w-full flex-col justify-start items-start flex gap-1">
                    <div className="flex flex-row md:flex-col justify-between items-start h-auto w-full gap-2 mt-5 ">
                        <div>
                            <HeaderButton buttonName="Add Order" isAdd={false}></HeaderButton>
                        </div>
                    </div>
                </div>

                <div className="w-full h-screen">
                  
                </div>
            </div>
        </>
    );
}

export default ProfilePage;
